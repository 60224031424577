@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$auth-background-color: white !default;
$auth-radial-gradient-primary: $color-primary-light !default;
$auth-radial-gradient-secondary: rgba(0, 92, 146, 0) !default;
$animation-duration: 20s;
$bg-color-account-membership: radial-gradient(
				52.92% 129.59% at 50% 50%,
				$auth-radial-gradient-primary 0%,
				$auth-radial-gradient-secondary 100%
) !default;

@property --a {
	syntax: "<percentage>";
	inherits: false;
	initial-value: 50%;
}
@property --b {
	syntax: "<percentage>";
	inherits: false;
	initial-value: 50%;
}
@property --x {
	syntax: "<percentage>";
	inherits: false;
	initial-value: 50%;
}
@property --y {
	syntax: "<percentage>";
	inherits: false;
	initial-value: 50%;
}

@keyframes gradientAnimation {
	0% {
		--a: 52.92%;
		--b: 129.59%;
		--x: 50%;
		--y: 50%;
	}
	20% {
		--a: 64.03%;
		--b: 104.52%;
		--x: 80.81%;
		--y: 79.71%;
	}
	40% {
		--a: 92.22%;
		--b: 98.78%;
		--x: 17.92%;
		--y: 85.12%;
	}
	60% {
		--a: 85.13%;
		--b: 104.4%;
		--x: 6.08%;
		--y: 9.89%;
	}
	80% {
		--a: 67.21%;
		--b: 141.53%;
		--x: 80.15%;
		--y: 18.01%;
	}
	100% {
		--a: 52.92%;
		--b: 129.59%;
		--x: 50%;
		--y: 50%;
	}
}

.background {
	&-container {
		position: absolute;
		inset: 0;
		background: $auth-background-color;
	}

	&__radial-gradient {
		position: absolute;
		inset: 0;
		background: var(--account-bg-membership, $bg-color-account-membership);


		&--animation-auto {
			background: radial-gradient(
							var(--a) var(--b) at var(--x) var(--y),
							$auth-radial-gradient-primary 0%,
							$auth-radial-gradient-secondary 100%
			);
			animation: gradientAnimation $animation-duration infinite;
			-webkit-animation-fill-mode: both;
			animation-fill-mode: both;
			-webkit-animation-timing-function: linear;
			animation-timing-function: linear;
		}
	}
}
