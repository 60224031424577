@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.carousel-arrow {
	border-radius: 100%;
	height: 40px;
	width: 40px;
	background: white;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	cursor: pointer;

	svg {
		color: $grey-dark;
		width: 18px;
		height: 18px;
	}
}
