@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.external-current-color-svg {
	display: flex;
	background-color: currentColor;
	mask-position: center;
	mask-repeat: no-repeat;
	mask-size: contain;
	-webkit-mask-position: center;
	-webkit-mask-repeat: no-repeat;
	-webkit-mask-size: contain;
}
