@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.photo-article {
	border-radius: $border-radius-big;

	$this: &;

	&__photo {
		width: 100%;
		height: 100%;

		img,
		.progressive-image {
			border-radius: $border-radius-big $border-radius-big 0 0;
		}

		@include gridle_state(md) {
			width: 270px;
			height: auto; // photo have to adapt his height to the text content

			.background-progressive-image,
			.background-progressive-image__image {
				border-radius: $border-radius-big 0 0 $border-radius-big;
			}
		}
	}

	&__content {
		padding: 10px;
		background: $grey-light;
		border-radius: 0 0 $border-radius-big $border-radius-big;

		@include gridle_state(md) {
			padding: 30px;
			flex: 1 1 0%;
			border-radius: 0 $border-radius-big $border-radius-big 0;
		}
	}

	&__sticker {
		float: right;
		height: 80px;
		width: 80px;

		@include gridle_state(md) {
			height: 105px;
			width: 105px;
		}
	}

	&__title {
		@extend %font-bold;
		color: $color-primary;
		font-size: 1.6rem;
		text-transform: uppercase;
		margin-top: 10px;
		margin-bottom: 15px;

		@include gridle_state(md) {
			font-size: 2rem;
			text-align: left;
		}
	}

	&__text {
		line-height: 1.5;

		p {
			margin-bottom: 10px;
		}

		a {
			display: inline-flex;
			align-items: center;

			.icon {
				margin-left: 5px;
				height: 13px;
				width: 13px;
			}
		}

		@include gridle_state(md) {
			font-size: 1.6rem;

			a {
				font-size: 1.6rem;
			}
		}
	}

	@include gridle_state(md) {
		display: flex;

		&--reverse {
			flex-direction: row-reverse;

			#{$this}__content {
				border-radius: $border-radius-big 0 0 $border-radius-big;
			}

			#{$this}__photo {
				.background-progressive-image,
				.background-progressive-image__image {
					border-radius: 0 $border-radius-big $border-radius-big 0;
				}
			}
		}
	}
}
