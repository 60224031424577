@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.one-merch-banner {
	$this: &;

	&__title {
		margin-bottom: 24px;
	}

	&__merch-title {
		margin-bottom: 12px;

		@include gridle_state(sm) {
			margin-bottom: 16px;
		}

		& * {
			display: inline-block;
		}

		.paragraphs p {
			margin-bottom: 0;
		}
	}

	&__merch-intro {
		font-size: 1.4rem;

		@include gridle_state(sm) {
			font-size: 1.5rem;
		}
	}

	&__cta {
		margin-top: 24px;
		display: inline-flex;

		svg {
			margin-left: 10px;
			height: 10px;
			width: 10px;
		}

		&.relative-link {
			@include gridle_state(sm) {
				width: fit-content;
				padding-left: 20px;
				padding-right: 20px;
			}
		}
	}

	&__image {
		position: relative;
		height: 100%;
		width: 100%;
		margin-bottom: 20px;
		cursor: pointer;

		.progressive-image {
			height: 100%;
			width: 100%;
		}

		img {
			border-radius: $border-radius-big;
		}

		@include gridle_state(sm) {
			width: 350px;
			height: 196px;
			margin-right: 20px;
			margin-top: 0;
			margin-bottom: 0;
		}

		@include gridle_state(md) {
			margin-right: 40px;
		}
	}

	&__stamp {
		position: absolute;
		bottom: 0;
		right: 0;

		@if $border-radius-big == 0 {
			border-bottom-right-radius: 0;
		} @else {
			border-bottom-right-radius: calc(
				$border-radius-big - 2px
			); // sur TO par exemple, on voit un bout de l'image derriere sans cet ajustement de 2px
			border-top-right-radius: calc(
				$border-radius-big - 2px
			); // sur TO par exemple, on voit un bout de l'image derriere sans cet ajustement de 2px
		}

		.decreasing-price-stamp {
			@if $border-radius-big == 0 {
				border-bottom-right-radius: 0;
			} @else {
				border-bottom-right-radius: calc(
					$border-radius-big - 2px
				); // sur TO par exemple, on voit un bout de l'image derriere sans cet ajustement de 2px
			}
		}

		&.one-merch-banner__stamp--left {
			right: auto;
			left: 0;

			border-bottom-left-radius: 0;
			border-top-left-radius: 0;

			border-bottom-right-radius: 0;

			@if $border-radius-big == 0 {
				border-top-right-radius: 0;
			} @else {
				border-top-right-radius: calc(
					$border-radius-big - 2px
				); // sur TO par exemple, on voit un bout de l'image derriere sans cet ajustement de 2px
			}

			.decreasing-price-stamp {
				@if $border-radius-big == 0 {
					border-radius: 0;
				} @else {
					border-radius: 0 calc($border-radius-big - 2px) 0 0;
				}
			}
		}
	}

	&__wrapper {
		display: flex;
		flex-direction: column;

		@include gridle_state(sm) {
			flex-direction: row;
			justify-content: center;
		}

		&.one-merch-banner__wrapper--reverse {
			@include gridle_state(sm) {
				flex-direction: row-reverse;
				justify-content: center;
			}

			#{$this}__image {
				margin-right: 0;

				@include gridle_state(sm) {
					margin-left: 20px;
				}

				@include gridle_state(md) {
					margin-left: 40px;
				}
			}
		}
	}

	&__container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex: 1 1 0%;
	}
}
