@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.dot-notification {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $button-primary-background-color;
	border-radius: 50%;
	color: #ffffff;
	font-size: 1rem;
	width: 15px;
	height: 15px;
	line-height: 1;
}
