@import "~themes/theme";

$input-focus-text-highligthed-color: #b3d7fe;

:root {
	--color-success-200: #EFFFF8;
	--color-success-300: #DFFFF2;
	--color-success-400: #88EAC1;
	--color-success-500: #0ABF74;
	--color-success-600: #089B5E;

	--color-info-200: #ECF8FF;
	--color-info-300: #DFF2FD;
	--color-info-400: #9BD4FF;
	--color-info-500: #3493DC;
	--color-info-600: #07326A;

	--color-warning-200: #FFF4F4;
	--color-warning-300: #FFEAEA;
	--color-warning-400: #FA7463;
	--color-warning-500: #C01814;
	--color-warning-600: #601E17;
}