@import "./breakpoint.scss";

$context: 24;
// number of columns in your grid
$column-width: null;
// by default it's percentage based depending on the context but you can specify a column width yourself
$gutter-width: 4px;
// size of the gutters
$gutter-height: 0;
// size of top and bottom gutters
$gutter-top: 0;
// size of top gutter
$gutter-bottom: 0;
// size of bottom gutter
$gutter-left: 2px;
// size of left gutter
$gutter-right: 2px;
// size of right gutter
$direction: ltr;
// direction
$dir-attribute: false;
// if need to generate support for dir="rtl" in order to set part of layout direction
$name-multiplicator: 1;
// if 5; generate gr-5; gr-10; etc...

@mixin between($min-screen, $max-screen) {
	@if map-has-key($breakpoint, $min-screen) == false or map-has-key($breakpoint, $max-screen) == false {
		@error "One of the breakpoints #{$min-screen} or #{$max-screen} does not exist...";
	}
	$min-width: map-get($breakpoint, $min-screen);
	$max-width: map-get($breakpoint, $max-screen);
	@media (min-width: $min-width) and (max-width: $max-width) {
		@content;
	}
}


@mixin gridle_state($screen) {
	@if map-has-key($breakpoint, $screen) == false {
		@error "the column #{$screen} does not exist...";
	}
	$min-width: map-get($breakpoint, $screen);
	@media (min-width: $min-width) {
	  @content;
	}
}

@mixin container {
	margin-right: auto;
	margin-left: auto;
	max-width: 978px;

	&:after {
		clear: both;
		content: "";
		display: table;
	}

	@include gridle_state(md) {
		max-width: 1024px;
	}
}

@mixin gridle_row {
	padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: -$gutter-left;
    margin-right: -$gutter-right;
	&:before,
	&:after {
		content:"";
		display:table;
	}
	&:after {
		clear:both;
	}
}

@mixin gridle_grid($columns) {
	// width :
	$width : 0;
	@if $column-width {
		$width : $column-width * $columns;
	} @else {
		$width : percentage(calc(1 / $context * $columns));
	}

	// set value :
	width:$width;

	min-height: 1px;
	float: left;
	box-sizing: border-box;
	vertical-align: top;
	padding-right: $gutter-right;
	padding-left: $gutter-left;
}

@mixin gridle_suffix($columns) {
	$width : 0;
	@if $column-width {
		$width : $column-width * ($columns / $name-multiplicator);
	} @else {
		$width : percentage(calc(1 / $context)) * calc($columns / $name-multiplicator);
	}

	// set value :
	@if $direction == rtl { margin-left:$width; }
	@else { margin-right:$width; }

	// handle direction attribute
	@if $dir-attribute {
		[dir="rtl"] & {
			margin-left: $width;
		}
		[dir="ltr"] & {
			margin-right: $width;
		}
	}
}

@mixin gridle_push($columns) {
	// vars :
	$width : 0;
	@if $column-width {
		$width : $column-width * ($columns / $name-multiplicator);
	} @else {
		$width : percentage(calc(1 / $context)) * calc($columns / $name-multiplicator);
	}
	// set value :
	@if $direction == rtl { right:$width;left:auto; }
	@else { left:$width;right:auto; }
	// handle direction attribute
	@if $dir-attribute {
		[dir="rtl"] & {
			right: $width;
		}
		[dir="ltr"] & {
			left: $width;
		}
	}
}
