@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
@import "~swiper/src/swiper.scss";

.current-merch-collection {
	.separator {
		width: 200px;
		margin: 20px auto 10px;

		@include gridle_state(md) {
			margin: 40px auto 30px;
		}
	}

	.swiper-container {
		padding-bottom: 3px;
	}

	&__title {
		margin-bottom: 15px;

		.separator {
			margin: 10px auto 20px;

			@include gridle_state(md) {
				margin: 30px auto 40px;
			}
		}

		@include gridle_state(md) {
			margin-bottom: 30px;
		}
	}

	&__list {
		display: flex;
		justify-content: space-between;
	}

	&__item {
		margin-right: 24px;
		width: 100%;

		&:last-of-type {
			margin-right: 0;
		}

		@include gridle_state(md) {
			margin-right: 32px;
		}
	}
}
