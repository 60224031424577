@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.click-to-call-cta {
	display: flex;
	justify-content: center;

	&__container {
		width: 280px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 15px;
	}

	&__schedules {
		font-size: 12px;
	}

	&__button {
		&.button {
			width: fit-content;
			height: 40px;
			background: none;
			color: $black;
			box-shadow: inset 0px 0px 0px 1px $black, 0 3px 6px 0 rgba(0, 0, 0, 0.16);
			border-radius: $border-radius-small;
			letter-spacing: 0;
			margin: 0; // delete space between input and button in ios

			&:focus {
				color: $black;
				box-shadow: inset 0px 0px 0px 1px $black, 0 3px 6px 0 rgba(0, 0, 0, 0.16);
				background: none;
			}

			&:hover {
				background: $black;
				color: #ffffff;
			}

			.button__text {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 8px;
				padding-right: 18px;
				padding-left: 18px;
				text-transform: none;
			}
		}
	}
}
