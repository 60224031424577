@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.book-with-confidence {
	text-align: center;
	background-color: $grey-light;
	border-top: 1px solid #d9d9d9;
	padding: 20px;

	&__container {
		@include container;
	}

	&__logo-container {
		display: flex;
		justify-content: center;
		align-items: center;

		@include gridle_state(md) {
			flex-direction: column;
			margin-right: 30px;
		}
	}

	&__logo {
		&:first-of-type {
			svg {
				margin-right: 30px;
			}
		}

		@include gridle_state(md) {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;

			&:first-of-type {
				svg {
					margin-right: 0;
				}
			}
		}
	}

	&__title {
		@extend %text-bold;
		font-size: 2.2rem;
	}

	&__block {
		margin-bottom: 15px;

		&:first-of-type {
			margin-bottom: 20px;
		}
	}

	&__block-title {
		@extend %text-bold;
		font-size: 1.7rem;
	}

	@include gridle_state(md) {
		&__inner-wrapper {
			display: flex;
		}

		&__two-blocks {
			display: flex;
			flex-direction: column;
			text-align: left;
		}
	}
}
