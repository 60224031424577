@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.aside-panel {
	position: fixed;
	top: 0;
	height: 100vh;
	z-index: 6;
	overflow-y: hidden;
	transition: transform 0.3s ease;
	// transform:none au lieu de transform: translateX(0) car sinon les position:fixed ne sont plus fixed
	// voir http://www.redips.net/web/fixed-element-css-transform
	transform: translateX(-100%);

	&--pushed {
		transform: translateX(0);
	}
}

.aside-panel-overlay {
	pointer-events: none;
	background-color: black;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100vh;
	width: 100vw;
	z-index: 3;
	opacity: 0;
	transition: opacity 0.2s ease;

	&--visible {
		opacity: 0.7;
	}
}

.app {
	transition: transform 0.3s ease;
	// transform:none au lieu de transform: translateX(0) car sinon les position:fixed ne sont plus fixed
	// voir http://www.redips.net/web/fixed-element-css-transform
	transform: none;

	&--pushed {
		transform: translateX(0);
	}
}
