@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
%grid-2-columns {
	display: flex;
	flex-direction: column;
	justify-content: center;

	@include gridle_state(md) {
		display: grid;
		grid-template-columns: repeat(2, calc(50% - 20px));
		grid-gap: 32px;
	}
}

%grid-3-columns {
	display: flex;
	flex-direction: column;
	justify-content: center;

	@include gridle_state(md) {
		display: grid;
		grid-template-columns: repeat(3, 325px);
		grid-column-gap: 24px;
		grid-row-gap: 32px;
	}
}

.product-list-block {
	&__title {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 24px;

		&--with-filter {
			margin-top: 24px;
		}
	}

	&__view-all-offers {
		&-link {
			.relative-link {
				@extend %link;
			}
		}

		&-button {
			margin-top: 24px;

			svg {
				margin-left: 8px;
			}
		}
	}

	&__products {
		@extend %grid-3-columns;
	}

	&__container {
		@include container;
	}

	&__filter {
		padding: 8px 0 24px;
		margin-bottom: 24px;
	}

	.products-carousel {
		padding-left: 0;
		padding-right: 0;

		&__item {
			min-height: 310px;
		}
	}

	.product-card {
		&__action {
			display: none;
		}
	}

	// display cta button for long version
	.product-list {
		.product-card {
			&__action {
				display: flex;
			}
		}
	}

	.product-list-block__products,
	.products-carousel {
		.product-card {
			&__picture {
				@include gridle_state(md) {
					height: 185px;

					.images-carousel-pp {
						&__image {
							height: 185px;
						}
					}
				}
			}
		}
	}
}
