@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.pp-carousel-block {
	flex: 1 1 0%;
	padding: 30px 15px;
	display: flex;
	flex-direction: column;

	@include gridle_state(sm) {
		width: 1024px;
		padding: 30px 0px;
	}

	&__title {
		margin-bottom: 20px;
	}

	&__cta {
		display: inline-flex;
		width: fit-content !important;
		padding-left: 20px !important;
		padding-right: 20px !important;
		margin-top: 24px;

		svg {
			margin-left: 10px;
			height: 10px;
			width: 10px;
		}
	}
}
