@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.dev-infos {
	position: fixed;
	bottom: 20px;
	right: 20px;
	background: white;
	padding: 20px;
	border-radius: $border-radius-big;
	border: 1px solid $grey-medium;
	z-index: 10;

	$this: &;

	&__release {
		background-color: $grey-dark;
		padding: 2px 8px;
		border-radius: 4px;
		color: white;
		margin-right: 30px;
		margin-bottom: 8px;
	}

	&__close {
		position: absolute;
		top: 17px;
		right: 20px;
		cursor: pointer;
		height: 15px;
		width: 15px;

		svg {
			height: 20px;
			width: 20px;
		}
	}

	&__item {
		margin-top: 8px;
	}

	&__slug {
		@extend %text-bold;
		display: inline-block;
		color: white;
		font-size: 1.2rem;
		background: $grey-dark;
		padding: 2px 8px;
		border-radius: 4px;
	}

	&__flags {
		margin-left: 25px;
		font-size: 1.2rem;
	}

	ul {
		list-style: initial;
	}

	&--collapse {
		width: 10px;
		padding-bottom: 20px;

		#{$this}__release,
		#{$this}__title,
		#{$this}__slug,
		#{$this}__env,
		#{$this}__flags {
			display: none;
		}

		#{$this}__clear-storage-cta {
			display: none;
		}
	}

	&__clear-storage-cta {
		margin-top: 20px;

		.button--primary {
			font-size: 1.2rem;
			min-height: 35px;
		}
	}
}
